html {
  font-size: 10px;
}

@font-face {
  font-family: "Toronto Subway";
  src: url("assets/fonts/licensed/toronto_subway_bold-web.woff2")
      format("woff2"),
    /* url("/assets/fonts/licensed/toront_subway_bold-web.woff") format("woff"); */
    url('assets/fonts/licensed/toronto_subway_bold-web.ttf');
    font-display: swap;
}

body {
  background: #f0ead6;
  font-family: 'Toronto Subway', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-size: 1.6rem;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.title {
  font-size: 12vw;
}
